function setEmailValueOnCopy (email) {
  const emailCopyInput = document.querySelector('.magic-link-modal .email-copy')
  emailCopyInput.innerHTML = emailCopyInput.innerHTML.replace(/<strong><\/strong>/, `<strong>${email}</strong>`)
}

function sendEmail (email, url) {
  const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content

  fetch(
    url,
    {
      method: 'POST',
      headers: {
        'X-CSRF-TOKEN': csrfToken,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        email
      })
    }
  )
}

function requestMagicLink (email, url) {
  setEmailValueOnCopy(email)
  sendEmail(email, url)
  document.querySelector('#check-your-email').setAttribute('open', true)
}

function addMagicEmailClickHandler (selector) {
  const element = document.querySelector(selector)
  if (!element) return

  element.addEventListener('click', event => {
    const magicLinkRequestUrl = event.target.dataset.url
    const emailFromData = event.target.dataset.email

    if (emailFromData) {
      requestMagicLink(emailFromData, magicLinkRequestUrl)
    } else {
      const emailInput = document.querySelector('input[type="email"]')

      if (emailInput) {
        const form = emailInput.closest('form')

        if (form.checkValidity() === false) {
          form.reportValidity()
          return
        }

        requestMagicLink(emailInput.value, magicLinkRequestUrl)
      }
    }
  })
}

addMagicEmailClickHandler('.magic-link-trigger')
addMagicEmailClickHandler('.magic-link-modal__resend')
